body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  font-style: thin;
}

* {
  box-sizing: border-box;
}
