img {
  width: 300px;
  height: auto;

  flex: 1;
}

@media (min-width: 768px) {
  .App::-webkit-scrollbar {
    display: none;
  }
  img:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  .title {
    font-size: 24px;
    font-style: thin;
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
    z-index: 99;
    padding: 0 10px;
    p {
      padding: 0px;
      margin: 0px;
    }
  }

  .App {
    height: 100vh;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .column {
    margin-right: 15px;
    margin-left: 15px;
    height: 100vh;
    overflow-y: hidden;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    div {
      max-height: calc(50vh - 15px);
      display: flex;
      flex: 1;
      overflow: hidden;
    }
    div:first-child {
      margin-bottom: 15px;
      align-items: end;
    }
    div:last-child {
      margin-top: 15px;
      align-items: start;
    }
  }
  .column:last-child {
    margin-right: 0px;
  }
  .row {
    width: calc((330px * 31));
  }
  .App-sm {
    display: none;
  }
}
@media (max-width: 768px) {
  .App {
    display: none;
  }
  .App-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 18px;
    font-style: thin;
    position: fixed;
    top: 10px;
    padding: 10px;
    right: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
    p {
      margin: 0px;
    }
  }
}
